export const warAreas = [
  {
    title: "东部战区",
    province: ["江苏", "浙江", "安徽", "福建", "上海", "山东"],
    address: "临沂总仓",
    linkName: "程强、李秀秋",
    phone: "15065981153"
  },
  {
    title: "南部战区",
    province: ["湖南", "贵州", "广西", "广东", "海南", "香港", "澳门", "台湾"],
    address: "南宁总仓",
    linkName: "胡世忠",
    phone: "19950705519"
  },
  {
    title: "西部战区",
    province: ["四川", "西藏", "陕西", "甘肃", "宁夏", "青海", "重庆", "云南", "新疆"],
    address: "成都总仓",
    linkName: "刘聪培",
    phone: "13548189368"
  },
  {
    title: "北部战区",
    province: ["辽宁", "吉林", "黑龙江", "内蒙古"],
    address: "沈阳总仓",
    linkName: "神维铜",
    phone: "13204516681"
  },
  {
    title: "中部战区",
    province: ["河北", "山西", "河南", "湖北", "北京", "天津", "江西"],
    address: "九江总仓",
    linkName: "李继雄、王漳琦",
    phone: "13920956226"
  }
]

export default function findWar(province: string) {
  return warAreas.find(area => area.province.find(i => new RegExp(i).test(province)));
}